import React, { useContext, useState } from "react";
import { techStack } from "../constants";
import { ThemeContext } from "../themeProvider";
import { motion } from "framer-motion";

const About = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  const [selectedTab, setSelectedTab] = useState("backend");
  const [selectedButton, setSelectedButton] = useState("backend");

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setSelectedButton(tab);
  };

  const techClassNames = "py-2 px-4 bg-gray-50 md:m-4 mx-2 mt-6 rounded-lg flex items-center hover:scale-125 cursor-pointer md:w-48 w-40 text-center text-xl justify-center border border-gray-300";

  return (
    <div id="about" className={darkMode === true ? "bg-white" : "bg-gray-900"}>
      <div className="max-w-7xl mx-auto x-4 sm:px-6 lg:px-8 px-4 md:mt-0 pt-24 pb-12">
        <h2
          className={
            darkMode
              ? "text-5xl font-bold px-4 md:px-0 text-center"
              : "text-5xl font-bold px-4 md:px-0 text-center text-white"
          }
        >
          About Me
        </h2>
        <div>
          <motion.div>
            <h4 className="mt-12 text-3xl font-semibold text-blue-500">
              A bit about me
            </h4>
            <p
              className={
                darkMode
                  ? "mt-4 text-xl text-justify text-gray-500"
                  : "mt-4 text-xl text-justify text-white"
              }
            >
              I am an experienced Software developer with <strong>4 years of experience </strong> in the Industry ,
              1 year professional Machine Learning Experience. I hold a <strong>Masters degree in Machine Learning</strong>,
              which has equipped me with in-depth knowledge and practical skills
              in developing intelligent systems. I am proficient in programming
              languages such as <strong> Python, Java and Kotlin</strong>, as well as frontend
              technologies like <strong>React</strong>. This allows me to create efficient and
              user-friendly software applications. With my expertise in backend
              development, machine learning, and a diverse set of programming
              languages, I am well-equipped to design and implement cutting-edge
              solutions that meet the highest standards of performance and user
              experience.
            </p>
          </motion.div>
          <motion.div>
            <h4 className="mt-12 text-3xl font-semibold text-blue-500">
              Technologies and Tools
            </h4>
            <p
              className={
                darkMode
                  ? "mt-4 text-xl text-justify text-gray-500"
                  : "mt-4 text-xl text-justify text-white"
              }
            >
              Using a combination of cutting-edge technologies and reliable
              open-source software I build user-focused and performant apps.
            </p>
            <motion.div className="flex">
            <button className={`${techClassNames}  ${selectedButton === "backend" ? "text-white-500 text-white !bg-blue-500" : ""}`} onClick={() => handleTabClick("backend")}>Backend</button>
            <button className={`${techClassNames} ${selectedButton === "frontend" ? "text-white-500 text-white !bg-blue-500" : ""}`} onClick={() => handleTabClick("frontend")}>Frontend</button>
            <button  className={`${techClassNames} ${selectedButton === "machineLearning" ? "text-white-500 text-white !bg-blue-500" : ""}`}  onClick={() => handleTabClick("machineLearning")}> Machine Learning</button>
            <button  className={`${techClassNames} ${selectedButton === "tools" ? "text-white-500 text-white !bg-blue-500" : ""}`}  onClick={() => handleTabClick("tools")}>Tools</button>
            </motion.div>
          </motion.div>
          <motion.div className="flex flex-wrap mt-8 flex flex-wrap justify-between ">
            {techStack[selectedTab].map((el, index) => (
              <motion.div
                initial="hidden"
                whileInView={"visible"}
                variants={{
                  visible: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "spring",
                    },
                  },
                  hidden: { opacity: 1, y: 80 },
                }}
                className="py-2 px-4 bg-gray-50 md:m-4 mx-2 mt-6 rounded-lg flex items-center hover:scale-125 cursor-pointer md:w-48 w-40"
              >
                <img alt="" src={el.link} className="w-12" />
                <h4 className="text-md ml-4">{el.name}</h4>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default About;
